.GrowYourBussionWrapper {
    background-color: var(--GrowYourBussionWrapper-bgColor);

}

.GrowYourBussion-wrapper {
    padding: 30px 20px;
    margin: 0 -20px;
}

.GrowYourBussion-wrapper .boxes {
    padding: 30px 0;
    text-align: center;
    gap: 50;
}
.GrowYourBussion-wrapper .align {
    margin-top: -260px;

}
.GrowYourBussion-wrapper .middle-wrapper {
    padding: 30px 0;
    text-align: center;
    gap: 50;

}

.GrowYourBussion-wrapper .middle-wrapper .heading-2 {
    margin: 30px 0;
}

.GrowYourBussion-wrapper .middle-wrapper .main-boxes {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.GrowYourBussion-wrapper .middle-wrapper .main-boxes .boxes {
    width: 380px;
    height: 137px;
    background-color: #FFFFFF !important;
    border: 1px solid #8B9CA83B;
    border-radius: 10px;
    opacity: 1;
}

.GrowYourBussion-wrapper .middle-wrapper .main-boxes .boxes img {
    margin-left: 20px;
    height: 60px;
    width: 60px;
}

.GrowYourBussion-wrapper .middle-wrapper .main-boxes .boxes .boxes-h2 {
    text-align: start;
    padding: 0px 20px 0px 20px;
}

.GrowYourBussion-wrapper .middle-wrapper .main-boxes .circle {
    height: 25px;
    width: 25px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    margin-left: -211px;
}

.GrowYourBussion-wrapper .middle-wrapper .box {
    padding: 0 30px;
    width: 287px;
    height: 220px;
    background-color: #FFFFFF !important;
    box-shadow: 0px 1px 20px #49494921;
    border-radius: 10px;
}
.GrowYourBussion-wrapper .middle-wrapper .box img{
    height: 65px;
    width: 65px;
    margin-top: 30px;
}

.GrowYourBussion-wrapper .middle-wrapper .box .text{
    margin-top: 14px;
  text-align: center;
}

.GrowYourBussion-wrapper .middle-wrapper .box .boxes-h2 {
    text-align: start;
    width: 165px;
    
}

.GrowYourBussion-wrapper .middle-wrapper .box.box-4 {
    border-right: none;
}

.GrowYourBussion-wrapper .middle-wrapper .img img {
    margin: 14px 0 0 30px;
}

@media only screen and (max-width: 767px) {
    .GrowYourBussion-wrapper {
        padding-bottom: 20px;
    }
    .GrowYourBussion-wrapper .middle-wrapper .center {
        display: flex;
        flex-direction: column;
    }

    .GrowYourBussion-wrapper .middle-wrapper .box {
        width: 44%;
        border: none;
    }
    .GrowYourBussion-wrapper .middle-wrapper .box .boxes {
        width: 44%;
        border: none;
    }
}

@media only screen and (max-width: 480px) {
    .GrowYourBussion-wrapper .middle-wrapper .box {
        width: 90%;
        border: none;
    }
}