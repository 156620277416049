.companyWrapper {
   background-color: #fff;
   padding: 40px 20px;
}

.companyWrapper .top {
   max-width: 860px;
   margin: auto;
}

.companyWrapper .top p {
   font-size: 20px;
}

.companyWrapper .card-box {
   flex-wrap: wrap;
   margin-top: 40px;
}

.companyWrapper .bottom {

   padding: 30px 0;
   margin: auto;

}

.companyWrapper .card-box .card {
   flex: 0 1 22%;
   min-height: 297px;
   background-color: var(--tab-content-bgColor);
   padding: 40px 20px 20px;
}

.companyWrapper .card-box .img {
   width: 93px;
   height: 93px;
   background-color: #fff;
   box-shadow: 0px 1px 2px #00000012;
   border-radius: 50%;

   margin: 0 auto 20px;
}

.companyWrapper .card-box .img img {
   display: block;
   margin-bottom: 0;
}

.companyWrapper .card-box .card h3 {
   margin-bottom: 6px;
}

.companyWrapper .middle {
   margin: 50px 0 30px;
}

.companyWrapper .middle>div {
   flex: 0 1 540px;
}

.companyWrapper .middle img {
   width: 100%;
}

@media only screen and (max-width: 767px) {
   .companyWrapper .middle {
      flex-direction: column;
      gap: 10px;
   }

   .companyWrapper .middle>div {
      flex: 0 1 auto;
   }

   .companyWrapper .card-box .card {
      flex: 0 1 44%;
      margin: auto;
   }

}

@media only screen and (max-width: 992px) {
   .companyWrapper .card-box .card {
      flex: 0 1 100%;
      max-width: 282px;
   }

}