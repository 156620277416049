:root {
  --header-bg_color: #4C369C;
  --header-color:#fff;
  --header-color_hover:#4AD728;
  --balance-heading:#f7c248;
  --balance-value:#f4f8fb;
  --profile-dropdown_color: #000;
  --profile-dropdown_color_hover: #FF6600;
  --profile-dropdown_bg_hover: #01483791;
  --login-btn-color:#fff;
  --login-btn-bg:#4AD728;
  --heading_2-color: #001A16;
  --logoName-bgColor:linear-gradient(to right, #cb3066, #f7c248);

  --tab-link-color: #fff;
  --tab-link-color-hover: #141414;
  --tab-link-bgColor:#F5F8F4;
  --tab-link-bgColor-hover: #E2D3CD;

  --tab-content-bgColor: #E2D3CD;
  --tab-content-boxShadow: 0 1px 1px #dcf47d;
  --tab-content-left-bgColor: #E2D3CD;
  --tab-content-input-color:#141414;
  --tab-content-input-border:1px solid rgba(0, 0, 0, 0.15);
  --tab-content-border: 1px solid #e5e5e5;

  --footer-border-color: #028f6e;
  --footer-border-link-color:#fff;
  --footer-contact-h2-color:#363636;
  --footer-img-shadow: 0px 1px 2px #00000036;
  --footer-socile-icon-bgColor: #514876;
  --footer-socile-icon-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(255, 255, 255, 0.1) 0px 0px 1px 0px;
  --footer-contact-img-bgColor: #FFF6DA;
  

  --GrowYourBussionWrapper-bgColor: #F7F4F8;
  --GrowYourBussion-wrapper-img-bgColor:#E6FF8294;

  --slick-active-color:#f7c248;
  --slick-dots-color: #fff;

  --section-gbColor-V1:#E6FF8294;
  --section-gbColor-V2: #FFFFFF;

  --blue: #1e90ff;
  --white: #ffffff;
}

